import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../styles/ProductsViewPage.scss";
import "../../styles/Products.scss";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/TravelReport.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { useReactToPrint } from "react-to-print";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import SalesBee from "../../images/MatricLogo.svg";
import { loadSingleTravelData } from "../../redux/action";
import moment from "moment";
import { baseURL } from "../BaseUrl";
import { FormattedAmount } from "../FormattedAmount";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function TravelReportDataTable(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let tableHeader = [
    { name: "DATE", align: "left", width: "120px" },
    { name: "FROM", align: "left", width: "200px" },
    { name: "TO", align: "left", width: "200px" },
    { name: "NO.OF KMs", align: "left", width: "100px" },
    { name: "TOTAL KMs", align: "left", width: "100px" },
    { name: "REMARKS", align: "left", width: "150px" },
    { name: "TOTAL BILL AMOUNT(Rs)", align: "left", width: "120px" },
  ];

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientId } = useParams();
  const { getSingleTravelReportData } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) =>
        row.fullName === currentValue.fullName &&
        row.totalClaimAmount === currentValue.totalClaimAmount &&
        row.meetingDate === currentValue.meetingDate &&
        row.dayName === currentValue.dayName
    );
    if (existingRow) {
      existingRow.singleTravelInfo.push({
        // meetingDate: currentValue.meetingDate,
        // dayName: currentValue.dayName,
        startLoc: currentValue.startLoc?.location,
        endLoc: currentValue.endLoc?.location,
        distanceTravelled: currentValue.distanceTravelled,
        remark: currentValue.remark,
      });
    } else {
      accumulator.push({
        fullName: currentValue.fullName,
        totalClaimAmount: currentValue.totalClaimAmount,
        meetingDate: currentValue.meetingDate,
        dayName: currentValue.dayName,
        singleTravelInfo: [
          {
            // meetingDate: currentValue.meetingDate,
            // dayName: currentValue.dayName,
            startLoc: currentValue.startLoc?.location,
            endLoc: currentValue.endLoc?.location,
            distanceTravelled: currentValue.distanceTravelled,
            remark: currentValue.remark,
          },
        ],
      });
    }
    return accumulator;
  };

  const mergedMotRows =
    getSingleTravelReportData.length > 0
      ? getSingleTravelReportData.reduce(mergeRows, [])
      : [];
  // console.log(mergedMotRows, "mergedMotRows");

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [imageClicked, setImageClicked] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);

  const handleImageClicked = (imageUrl) => {
    setImageClicked(!imageClicked);
    setClickedImageUrl(imageUrl);
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const componentRef = useRef();
  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      TRAVEL REPORT ATTACHMENTS
                    </h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col"></Col>
              </Row>
            </div>

            <div className="sb-table-div sb-table-setion">
              <div>
                <Paper
                  sx={{ width: "100%", overflow: "hidden" }}
                  className="table-main-div"
                >
                  <TableContainer
                    sx={{ maxHeight: 440 }}
                    // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                  >
                    <div ref={componentRef} className="table-data_n_images">
                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className={`whatsapp-table-container`}
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            <TableCell align="center" className="b-1">
                              <div className="travel-report-client-image">
                                <img
                                  src={
                                    resPassData?.subscription?.company
                                      ?.isWhiteList == 1
                                      ? resPassData?.subscription?.company
                                          ?.isWhiteListPath
                                      : SalesBee
                                  }
                                  alt="Logo"
                                />
                              </div>
                            </TableCell>

                            <TableCell
                              align="center"
                              colSpan={2}
                              className="reprot-table-hading b-1"
                            >
                              LOG SHEET FOR CONVEYANCE AND REIMBURSEMENT CLAIM
                            </TableCell>
                            <TableCell align="center" className="b-1">
                              {resPassData?.subscription?.company
                                ?.isWhiteList == 1 ? (
                                <div className="travel-report-client-image">
                                  <img src={SalesBee} alt="" />
                                </div>
                              ) : (
                                ""
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            {getSingleTravelReportData &&
                            getSingleTravelReportData.length === 0 ? (
                              <TableBody>
                                <TableRow align="center">
                                  <TableCell
                                    align="center"
                                    colSpan={props.tableHeader.length}
                                  >
                                    <div style={{ padding: "20px 20px" }}>
                                      <img
                                        src="../../../../images/DefaultImages/NodataFound.jpg"
                                        alt=""
                                        style={{ width: "10%" }}
                                      />
                                      <br />
                                      <span>--- No Data Found ---</span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                <TableRow align="center">
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="bl-1 br-1"
                                  >
                                    <b>EMPLOYEE NAME :</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div className="table-row-head-Text color-green">
                                      {getSingleTravelReportData[0]?.fullName ??
                                        "NA"}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div>
                                      <b>Employee Code :</b>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div className="table-row-head-Text">
                                      {getSingleTravelReportData[0]?.userCode ??
                                        "NA"}
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="bl-1 br-1"
                                  >
                                    <div>
                                      <b>MOT :</b>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div className="table-row-head-Text">
                                      {getSingleTravelReportData[0]
                                        ?.modeOfTravel ?? "NA"}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div>
                                      <b>Reporting To :</b>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div className="table-row-head-Text">
                                      <div className="table-row-head-Text">
                                        {getSingleTravelReportData[0]
                                          ?.managerName ?? "NA"}
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="bl-1 br-1"
                                  >
                                    <div>
                                      <b>Claim for the month of : </b>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div className="table-row-head-Text">
                                      {/* {getSingleTravelReportData[0]?.modeOfTravel} */}
                                      {/* {getSingleTravelReportData[0]?.modeOfTravel} */}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div>
                                      <b>Claim Date : </b>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "25%",
                                    }}
                                    className="br-1"
                                  >
                                    <div className="table-row-head-Text color-yellow">
                                      {getSingleTravelReportData[0]
                                        ?.meetingDate ?? "NA"}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </>
                        )}
                      </Table>

                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="whatsapp-table-container p-2"
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            {tableHeader.map((name, i) => (
                              <TableCell
                                key={i}
                                align={name.align}
                                style={{
                                  minWidth: name.width,
                                }}
                                className="bt-1"
                              >
                                {name.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            {getSingleTravelReportData &&
                            getSingleTravelReportData.length === 0 ? (
                              <TableBody>
                                <TableRow align="center">
                                  <TableCell
                                    align="center"
                                    colSpan={props.tableHeader.length}
                                  >
                                    <div style={{ padding: "20px 20px" }}>
                                      <img
                                        src="../../../../images/DefaultImages/NodataFound.jpg"
                                        alt=""
                                        style={{ width: "10%" }}
                                      />
                                      <br />
                                      <span>--- No Data Found ---</span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : (
                              <TableBody>
                                {mergedMotRows.length > 0 &&
                                  mergedMotRows.map((data, i) => (
                                    <TableRow
                                      align="center"
                                      key={i}
                                      className="client-list-row-data"
                                    >
                                      <TableCell className="tr-tcell-br bl-1">
                                        <div className="table-row-head-Text">
                                          {data?.dayName}
                                        </div>
                                        {data?.meetingDate}
                                      </TableCell>
                                      <TableCell colSpan={5}>
                                        {data.singleTravelInfo.map(
                                          (item, i) => (
                                            <TableRow
                                              key={i}
                                              className="table_row-inside-tabel-cell"
                                            >
                                              <TableCell className="tr-tcell-br">
                                                <div className="w-200px">
                                                  {item?.startLoc ?? "NA"}
                                                </div>
                                              </TableCell>
                                              <TableCell className="tr-tcell-br">
                                                <div className="w-200px">
                                                  {item?.endLoc ?? "NA"}
                                                </div>
                                              </TableCell>
                                              <TableCell className="tr-tcell-br">
                                                <div className="w-100px">
                                                  {item?.distanceTravelled ??
                                                    "NA"}
                                                </div>
                                              </TableCell>
                                              <TableCell className="tr-tcell-br">
                                                <div className="w-100px">-</div>
                                              </TableCell>
                                              <TableCell className="tr-tcell-br">
                                                <div className="w-150px">
                                                  {item?.remark ?? "NA"}
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableCell>
                                      <TableCell className="tr-tcell-br w-120px">
                                        <div className="price-text-div">
                                          <div className="price-div">
                                            <span className="price-text">
                                              <i
                                                className="fa fa-inr"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              {FormattedAmount(
                                                data?.totalClaimAmount ?? "NA"
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))}

                                {/* {props.loading &&
                                            <OnScrollLoading />
                                        } */}
                              </TableBody>
                            )}
                          </>
                        )}
                      </Table>
                      <div>
                        <div className="travel-report-download-images">
                          <div className="attachment-label_n_image">
                            <div style={{ width: "100%" }}>
                              <p>Hotel Claim Attachments :</p>
                              <div className="claim-attached-imgs">
                                {getSingleTravelReportData.length > 0 &&
                                getSingleTravelReportData[0].hotelAttachment !==
                                  null &&
                                getSingleTravelReportData[0].hotelAttachment !==
                                  ""
                                  ? getSingleTravelReportData[0].hotelAttachment
                                      ?.split(",")
                                      ?.map((imageUrl, i) => (
                                        <img
                                          key={i}
                                          src={`${baseURL}/${imageUrl.trim()}`}
                                          alt="image"
                                        />
                                      ))
                                  : "No Attachment"}
                              </div>
                            </div>
                          </div>
                          <div className="attachment-label_n_image">
                            <div>
                              <p>Food Claim Attachments :</p>
                              <div className="claim-attached-imgs">
                                {getSingleTravelReportData.length > 0 &&
                                getSingleTravelReportData[0]
                                  .foodClaimAttachment !== null &&
                                getSingleTravelReportData[0]
                                  .foodClaimAttachment !== ""
                                  ? getSingleTravelReportData[0].foodClaimAttachment
                                      .split(",")
                                      .map((imageUrl, i) => (
                                        <img
                                          key={i}
                                          src={`${baseURL}/${imageUrl.trim()}`}
                                          alt=""
                                        />
                                      ))
                                  : "No Attachment"}
                              </div>
                            </div>
                          </div>
                          <div className="attachment-label_n_image">
                            <div>
                              <p>Others Claim Attachments :</p>
                              <div className="claim-attached-imgs">
                                {getSingleTravelReportData.length > 0 &&
                                getSingleTravelReportData[0]
                                  .othersClaimAttachment !== null &&
                                getSingleTravelReportData[0]
                                  .othersClaimAttachment !== ""
                                  ? getSingleTravelReportData[0].othersClaimAttachment
                                      .split(",")
                                      .map((imageUrl, i) => (
                                        <img
                                          key={i}
                                          src={`${baseURL}/${imageUrl.trim()}`}
                                          alt=""
                                        />
                                      ))
                                  : "No Attachment"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            <div className="download-and-pagination">
              <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
